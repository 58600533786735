import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';
import {
  type AriaOverlayProps,
  useModal,
  useOverlay,
} from '@react-aria/overlays';
import { fonts, shadows, spacing } from 'folio-common-components';
import * as React from 'react';

type Props = AriaOverlayProps & {
  title: string;
  containFocus?: boolean;
  autoFocus?: boolean;
  transition?: boolean;
};

// react-aria's usePreventScroll messes up our styles. This is an old
// version of theirs.
function usePreventScroll() {
  React.useEffect(() => {
    const { paddingRight, overflow } = document.body.style;
    document.body.style.paddingRight = `${
      window.innerWidth - document.documentElement.clientWidth
    }px`;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = overflow;
      document.body.style.paddingRight = paddingRight;
    };
  });
}

export const ModalDialog: React.FC<React.PropsWithChildren<Props>> = props => {
  const {
    title,
    children,
    containFocus = true,
    autoFocus = false,
    transition = true,
    ...rest
  } = props;

  // Used to trigger the transition
  const [justOpened, setJustOpened] = React.useState(false);
  React.useEffect(() => {
    setJustOpened(true);
  }, []);

  const ref = React.useRef<HTMLDivElement>(null);
  const { overlayProps } = useOverlay(props, ref);
  const { modalProps } = useModal();
  const { dialogProps } = useDialog({}, ref);
  usePreventScroll();

  return (
    <Backdrop opened={justOpened} transition={transition}>
      {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
      <FocusScope restoreFocus autoFocus={autoFocus} contain={containFocus}>
        <Dialog
          {...overlayProps}
          {...dialogProps}
          {...modalProps}
          ref={ref}
          aria-label={title}
          opened={justOpened}
          transition={transition}
          {...rest}
        >
          {children}
        </Dialog>
      </FocusScope>
    </Backdrop>
  );
};

const Backdrop = styled.div<{ opened: boolean; transition: boolean }>`
  position: fixed;
  z-index: 4;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow: auto;
  transition: ${({ transition }) => (transition ? 'background 0.4s' : null)};
  background: ${({ opened }) => (opened ? 'rgba(0, 0, 0, .2)' : null)};
  ${spacing.getSpacing([16], 'padding')};
`;

const Dialog = styled.div<{ opened: boolean; transition: boolean }>`
  position: relative;
  background: #fff;
  border-radius: 8px;
  text-align: center;
  margin: auto;
  transition: ${({ transition }) => (transition ? 'opacity 0.4s' : null)};
  opacity: ${({ opened }) => (opened ? 1 : 0)};
  max-width: 400px;
  width: 100%;
  ${spacing.getSpacing([16], 'padding')};

  &,
  &.focus-visible {
    box-shadow: ${shadows.largeShadow};
  }
`;

export const dialogHeadingStyle = css`
  ${fonts.font300demi};
  margin: 0;
`;
