import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  PlainButton,
  focusShadow,
  fonts,
  spacing,
} from 'folio-common-components';
import { formatters } from 'folio-common-utils';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { cardStyle } from '../../components/card-style';
import { touchFeedback } from '../../styles/touch-feedback';
import { getReactRoot } from '../../utils/react-root';
import type { AccountSummary } from './summary';

export const AccountTypeContainer = styled.div`
  display: grid;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  grid-template-columns: 1fr auto;
  ${spacing.getSpacing([16], 'padding')};
`;

interface CollapsedSummaryProps {
  accountSummary: AccountSummary;
  isExpanded: boolean;
}

export const AmountSpan: React.FC<{ amount: number }> = ({ amount }) => (
  <span
    css={css`
      /* These shouldn't be responsive (mobile on 400medium) */
      font-size: 1.25rem;
      line-height: 1.3;
      font-weight: 500;
    `}
  >
    {formatters.formatAmount(amount, { currency: 'kr' })}
  </span>
);

const CollapsedSummary: React.FC<CollapsedSummaryProps> = ({
  accountSummary,
  isExpanded,
}) => (
  <AccountTypeContainer>
    <div>
      <AmountSpan amount={accountSummary.amount} />
      <div
        css={css`
          color: ${accountSummary.expandedContent == null
            ? undefined
            : colors.blue};
          display: grid;
          ${spacing.getSpacing([4], 'gap')};
          align-items: center;
          grid-template-columns: max-content max-content;
        `}
      >
        <span css={fonts.font200medium}>{accountSummary.title}</span>
        {accountSummary.expandedContent ? (
          <ArrowIcon
            css={css`
              overflow: visible;
              transform: ${isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)'};

              path {
                transform: ${isExpanded
                  ? 'translateY(-1px)' // visually adjust
                  : null};
              }
            `}
          />
        ) : null}
      </div>
    </div>
    <span
      css={css`
        ${spacing.getSpacing([8], 'margin-right')};
        display: flex; /* Aligns the icon by avoiding space for descender */
      `}
    >
      {accountSummary.icon}
    </span>
  </AccountTypeContainer>
);

export const NarrowAccountSummary: React.FC<{
  accountSummary: AccountSummary;
  isExpanded: boolean;
  onClick: () => void;
}> = ({ accountSummary, isExpanded, onClick }) => {
  const { expandedContent } = accountSummary;

  const collapsedSummary = (
    <CollapsedSummary accountSummary={accountSummary} isExpanded={isExpanded} />
  );

  return (
    <div
      css={css`
        position: relative;
        :not(:last-child) {
          border-bottom: 1px solid ${colors.grayMedium};
        }

        @media (prefers-contrast: more) {
          :not(:last-child) {
            border-bottom: 1px solid ${colors.black};
          }
        }
      `}
    >
      {expandedContent ? (
        <>
          <PlainButton
            onClick={onClick}
            aria-expanded={isExpanded}
            css={css`
              width: 100%;
              ${touchFeedback};
            `}
          >
            {collapsedSummary}
          </PlainButton>
          {isExpanded && (
            <div
              css={css`
                ${spacing.getSpacing(
                  [16],
                  'padding-horizontal',
                  'padding-bottom',
                )};
              `}
            >
              {expandedContent}
            </div>
          )}
        </>
      ) : (
        collapsedSummary
      )}
    </div>
  );
};

export const WideAccountSummary: React.FC<{
  accountSummary: AccountSummary;
}> = ({ accountSummary }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!isExpanded) {
      return;
    }

    function collapse(event: MouseEvent) {
      if (!containerRef.current?.contains(event.target as Node)) {
        setIsExpanded(false);
      }
    }

    getReactRoot().addEventListener('click', collapse, true);

    return () => {
      getReactRoot().removeEventListener('click', collapse, true);
    };
  }, [isExpanded]);

  const { expandedContent } = accountSummary;
  const collapsedSummary = (
    <CollapsedSummary accountSummary={accountSummary} isExpanded={isExpanded} />
  );

  return (
    <div
      data-testid={`accountsummary-${accountSummary.type}`}
      ref={containerRef}
      css={css`
        ${cardStyle};
        overflow: visible;
        position: relative;
        ${isExpanded
          ? css`
              box-shadow: none;
              border-radius: 8px 8px 0 0;
            `
          : null};
      `}
    >
      {expandedContent ? (
        <>
          <PlainButton
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
            css={css`
              width: 100%;
              ${touchFeedback};
              border-radius: 8px;

              &[data-focus-visible-added] {
                box-shadow: ${isExpanded ? 'none' : focusShadow};
              }

              &[data-focus-visible-added] + div {
                box-shadow: ${focusShadow};
              }
            `}
          >
            {collapsedSummary}
          </PlainButton>
          {isExpanded && (
            <div
              css={css`
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                pointer-events: none;
                width: 100%;
                ${cardStyle};
                background: transparent;
              `}
            >
              {/* This is used to take up the same amount of space
              as the button on top */}
              <div
                aria-hidden="true"
                css={css`
                  visibility: hidden;
                `}
              >
                {collapsedSummary}
              </div>
              <div
                css={css`
                  ${spacing.getSpacing(
                    [16],
                    'padding-horizontal',
                    'padding-bottom',
                  )};
                  background: #fff;
                  pointer-events: all;
                `}
              >
                {expandedContent}
              </div>
            </div>
          )}
        </>
      ) : (
        collapsedSummary
      )}
    </div>
  );
};

const ArrowIcon: React.FC = props => (
  <svg width={16} height={16} fill="none" {...props}>
    <circle cx={8} cy={8} r={8} fill={colors.blueLightOpaque} />
    <path
      d="M4 7l4 4 4-4"
      stroke="#5A3DE6"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
