import styled from '@emotion/styled';
import { shadows, spacing } from 'folio-common-components';
import { colors } from 'folio-design-tokens';
import { showDevStyles } from '../../env-utils/show-dev-styles';

const shouldShowDevStyles = showDevStyles();

export const HeaderBar = styled.header`
  ${spacing.getSpacing([24, 32], 'margin-bottom')};
  box-shadow: ${shadows.smallShadow};
  background-color: ${shouldShowDevStyles ? colors.yellowLightOpaque : '#fff'};

  @media (prefers-contrast: more) {
    box-shadow: 0 1px 0 0 ${colors.black};
  }
`;
