import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PlainButton, fonts, shadows, spacing } from 'folio-common-components';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { XForCloseIcon } from '../../icons';
import { touchFeedback } from '../../styles/touch-feedback';

export const zIndex = 2;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex};
`;

export const menuLinkStyle = css`
  ${fonts.font200medium};
  padding: 8px 0;
  color: ${colors.black};
  text-decoration: none;
  display: block;
  width: 100%;
`;

const StyledMenu = styled.div<{ isOpen: boolean; position: 'left' | 'right' }>`
  background: #fff;
  box-shadow: ${shadows.mediumShadow};
  border-radius: 8px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: ${zIndex};
  ${spacing.getSpacing([24], 'padding')};
  transition: opacity 0.35s, transform 0.35s;
  will-change: transform, opacity;
  overflow: hidden;

  transform-origin: ${({ position }) =>
    position === 'right' ? '100% 0' : '0 0'};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  pointer-events: ${({ isOpen }) => (isOpen ? null : 'none')};

  @media ${spacing.mediumMq} {
    max-width: 250px;
    width: 100%;

    left: ${({ position }) => (position === 'right' ? 'auto' : null)};
    right: ${({ position }) => (position === 'left' ? 'auto' : null)};
  }

  @media (prefers-reduced-motion: no-preference) {
    transform: ${({ isOpen }) => (isOpen ? null : 'scale(0.97)')};
  }

  @media (prefers-contrast: more) {
    box-shadow: 0 0 0 1px ${colors.black};
  }
`;

export type RenderFun = (
  isOpen: boolean,
  onClose: () => void,
) => React.ReactNode;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  position: 'left' | 'right';
  isOpen: boolean;
  openButton: React.RefObject<HTMLButtonElement>;
  onClose: () => void;
  showCloseButton?: boolean;
  menuContent?: RenderFun;
}

export const OverlayMenu: React.FC<Props> = props => {
  const {
    children,
    isOpen,
    menuContent,
    onClose,
    openButton,
    showCloseButton,
    ...rest
  } = props;
  const [focusOpenButtonNext, setFocusOpenButtonNext] = React.useState(false);
  const closeButton = React.useRef<HTMLButtonElement>(null);
  const menu = React.useRef<HTMLDivElement>(null);

  const devtoolsWorkaround = useDevtoolsWorkaround();

  const close = React.useCallback(
    (focusOpenButton = true) => {
      devtoolsWorkaround.reset();
      setFocusOpenButtonNext(focusOpenButton);
      onClose();
    },
    [onClose, devtoolsWorkaround],
  );

  const closeOnEsc = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        close();
      }
    },
    [close],
  );

  React.useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', closeOnEsc);
      closeButton.current?.focus({ preventScroll: true });
    } else if (focusOpenButtonNext) {
      openButton.current?.focus({ preventScroll: true });
    }

    return () => {
      document.removeEventListener('keydown', closeOnEsc);
    };
  }, [closeOnEsc, focusOpenButtonNext, isOpen, openButton]);

  return (
    <>
      {isOpen ? <Backdrop onClick={() => close(false)} /> : null}
      <StyledMenu isOpen={isOpen} ref={menu} aria-hidden={!isOpen} {...rest}>
        {showCloseButton !== false && (
          <PlainButton
            css={css`
              position: absolute;
              padding: 8px;
              top: 6px;
              right: 0;

              :focus {
                outline: none;
              }

              ${touchFeedback};
            `}
            ref={closeButton}
            aria-label="Lukk meny"
            tabIndex={isOpen ? undefined : -1}
            onClick={() => {
              if (devtoolsWorkaround.wasDeliberateInteraction()) {
                close();
              }
            }}
            onPointerDown={() => devtoolsWorkaround.setGotPointerDown()}
            onPointerUp={() => devtoolsWorkaround.setGotPointerUp()}
          >
            <XForCloseIcon
              css={css`
                display: block;
              `}
            />
          </PlainButton>
        )}
        {menuContent ? menuContent(isOpen, close) : children}
      </StyledMenu>
    </>
  );
};

// Work around a Chrome Devtools bug in device emulation mode, where a click is
// dispatched on this button when opening the menu, which immediately closes it.
// By checking that we got a pointerdown before the click, we know that it wasn't
// accidentally triggered.
function useDevtoolsWorkaround() {
  const gotPointerDown = React.useRef(false);
  const gotPointerUp = React.useRef(false);

  return React.useMemo(() => {
    return {
      setGotPointerDown() {
        gotPointerDown.current = true;
      },
      setGotPointerUp() {
        gotPointerUp.current = true;
      },
      reset() {
        gotPointerDown.current = false;
        gotPointerUp.current = false;
      },
      wasDeliberateInteraction() {
        // The important part is to check that we got a pointerdown event.
        // We check that they are both `true` or both `false` just in case no
        // pointer events at all are fired.
        return gotPointerDown.current === gotPointerUp.current;
      },
    };
  }, []);
}
