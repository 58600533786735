import { css } from '@emotion/react';
import { fonts, spacing } from 'folio-common-components';
import * as React from 'react';
import type { AvatarId } from '../../gqltypes';
import { textOverflow } from '../../styles/text-overflow';
import { getAvatarComponent } from '../avatar-image';

export const NavAvatarBox: React.FC<{
  avatarPosition?: 'right' | 'left';
  orgName?: string;
  name: string;
  avatarId: AvatarId;
}> = props => {
  const { avatarId, name, orgName } = props;
  const avatarPosition = props.avatarPosition || 'right';
  const Avatar = getAvatarComponent(avatarId);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: ${avatarPosition === 'left' ? null : 'row-reverse'};
        align-items: center;
      `}
    >
      <div
        css={css`
          width: 48px;
          height: 48px;
          overflow: hidden;
          border-radius: 50%;
          display: flex;
          align-items: center;
        `}
      >
        <Avatar />
      </div>
      <div
        css={css`
          text-align: ${avatarPosition === 'left' ? 'left' : 'right'};
          ${fonts.font100book};
          ${avatarPosition === 'left'
            ? spacing.getSpacing([16], 'padding-left')
            : spacing.getSpacing([16], 'padding-right')};
          ${textOverflow};
          max-width: 300px;
        `}
      >
        {name}
        {orgName && (
          <div
            css={css`
              ${fonts.font100medium};
              ${textOverflow};
            `}
          >
            {orgName}
          </div>
        )}
      </div>
    </div>
  );
};
