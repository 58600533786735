import { css } from '@emotion/react';
import { focusShadow, fonts, spacing } from 'folio-common-components';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { useLocationMatch } from '../hooks/use-location-match';
import { touchFeedback } from '../styles/touch-feedback';
import { OrgLink } from './org-navigation';

export type LinkProps = {
  url: string;
  label: React.ReactElement | string;
};

export const TabNav: React.FC<{ links: LinkProps[] }> = ({ links }) => (
  <nav css={[spacing.getSpacing([32], 'margin-bottom'), tabBarStyle]}>
    {links.map(link => (
      <Link key={link.url} to={link.url}>
        {link.label}
      </Link>
    ))}
  </nav>
);

const Link: React.FC<{ to: string; children: LinkProps['label'] }> = ({
  to,
  children,
}) => {
  const { isMatch } = useLocationMatch(to);
  return (
    <OrgLink to={to} css={[tabStyle, isMatch && tabActiveStyle]}>
      {children}
    </OrgLink>
  );
};

export const tabBarStyle = css`
  border-bottom: 2px solid ${colors.grayMedium};
`;

export const tabStyle = css`
  text-decoration: none;
  display: inline-block;
  position: relative;
  color: var(--muted-color);
  ${spacing.getSpacing([8], 'padding-vertical')};
  ${spacing.getSpacing([4], 'padding-horizontal')};
  ${fonts.font300medium};
  position: relative;
  border-radius: 0;
  transition: color 0.2s;

  --active-underline-color: transparent;

  ::before {
    content: '';
    height: 2px;
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    background: var(--active-underline-color);
  }

  :not(:last-of-type) {
    ${spacing.getSpacing([16], 'margin-right')};
  }

  :hover {
    color: inherit;
  }

  &.focus-visible {
    box-shadow: none;
  }

  &.focus-visible::after {
    content: '';
    position: absolute;
    top: 0;
    right: -4px;
    bottom: 0;
    left: -4px;
    box-shadow: ${focusShadow};
    border-radius: 8px;
  }

  ${touchFeedback};
`;

export const tabActiveStyle = css`
  color: inherit;
  --active-underline-color: ${colors.blue};
`;
