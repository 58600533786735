import { useLazyQuery, useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { OverlayContainer } from '@react-aria/overlays';
import { useOverlayTriggerState } from '@react-stately/overlays';
import * as Sentry from '@sentry/react';
import { encryptPinReadData, yearAndMonthToExpiryDate } from 'bank-common';
import { isToday } from 'date-fns';
import {
  Button,
  ButtonLink,
  PlainButton,
  fonts,
  spacing,
} from 'folio-common-components';
import {
  formatters,
  invariantValue,
  makeFikenDeepLink,
} from 'folio-common-utils';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { useMediaLayout } from 'use-media';
import {
  ActivateCardDocument,
  DismissAgentHintDocument,
  LookupCardPinDocument,
} from '../../common-client-queries.generated';
import {
  FirstPaintDocument,
  type FirstPaintQuery,
} from '../../common-queries.generated';
import { isProd } from '../../env-utils/is-prod';
import type { AgentHintKind } from '../../gqltypes';
import { useFirstPaintData } from '../../hooks/use-first-paint-data';
import { XForCloseIcon } from '../../icons';
import {
  ArigatouIllustration,
  EmptyChestIllustration,
  MarieKondoIllustration,
  MicroCardletterIllustration,
  MicroLightbulbIllustration,
  MoneyToWhereIllustration,
  SuccessMarkIllustration,
  SuperbossIllustration,
} from '../../illustrations';
import { pages } from '../../pages';
import { CvvInput } from '../../routes/profile/pin-viewer/cvvinput';
import { HintBox, HintBoxTitle } from '../HintBox';
import { LinkButton } from '../LinkButton';
import { ChatButton } from '../chat-button';
import { ModalDialog, dialogHeadingStyle } from '../modal-dialog';
import { OrgLink, useOrgNavigate } from '../org-navigation';

export function useIsAgentHintActive(kind: AgentHintKind) {
  const { data } = useFirstPaintData();

  if (!data) {
    return false;
  }

  return data.me.activeHints
    .find(e => e.kind === kind)
    ?.targets.includes('web');
}

function useAgentHint(kind: AgentHintKind): [boolean, () => void] {
  const [gqlDismissAgentHint] = useMutation(DismissAgentHintDocument);
  const [isDismissed, setIsDismissed] = React.useState(false);
  const isAgentHintActive = useIsAgentHintActive(kind);

  const dismissCallback = React.useCallback(async () => {
    setIsDismissed(true);
    await gqlDismissAgentHint({ variables: { agentHintKind: kind } });
  }, [gqlDismissAgentHint, kind]);

  if (isDismissed || !isAgentHintActive) {
    return [false, dismissCallback];
  }

  return [isAgentHintActive, dismissCallback];
}

export function useHasFikenDirectIntegration() {
  const firstPaintData = useFirstPaintData();
  return (
    firstPaintData.data?.organization.accountingSystemInfo.systemId ===
    'FikenDirectIntegration'
  );
}

const Container = styled.div`
  ${spacing.getSpacing([32, 32, 40], 'margin-bottom')}
`;

const HintWithIllustration: React.FC<
  React.PropsWithChildren<{
    illustration: React.ReactElement;
    onDismiss?: () => void;
  }>
> = props => {
  const { children, illustration, onDismiss } = props;
  const isBig = useMediaLayout(spacing.mediumMq);
  return (
    <HintBox onDismiss={onDismiss}>
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr auto;
        `}
      >
        {isBig ? (
          <>
            <div
              css={css`
                ${spacing.getSpacing([32, 32, 40], 'padding-right')};
                align-self: center;
              `}
            >
              {children}
            </div>
            <div
              css={css`
                align-self: center;
              `}
            >
              {illustration}
            </div>
          </>
        ) : (
          <div>
            <div
              css={css`
                text-align: center;
                /* 16px padding */
                ${spacing.getSpacing([16], 'margin-top')};
                ${spacing.getSpacing([24], 'margin-bottom')};
              `}
            >
              {illustration}
            </div>
            {children}
          </div>
        )}
      </div>
    </HintBox>
  );
};

export const SmallHintWithIllustration: React.FC<
  React.PropsWithChildren<{
    illustration: React.ReactElement;
  }>
> = props => {
  const { children, illustration } = props;
  return (
    <div
      css={css`
        background: ${colors.greenLightOpaque};
        border-radius: 8px;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        ${spacing.getSpacing([16], 'padding', 'margin-bottom', 'gap')};
      `}
    >
      {illustration}
      <div>{children}</div>
    </div>
  );
};

export const BeforeFirstFolioAccountTransferHint: React.FC = () => {
  const [shouldShowHint] = useAgentHint('beforeFirstFolioAccountTransferAdmin');
  const { data } = useFirstPaintData();

  if (!shouldShowHint) {
    return null;
  }

  if (!data?.organization.operationalAccount) {
    return null;
  }

  const accountNumber = formatters.formatAccountNumber(
    data.organization.operationalAccount.accountNumber,
  );

  return (
    <Container>
      <HintWithIllustration illustration={<SuperbossIllustration />}>
        <HintBoxTitle>Kom i gang med Folio</HintBoxTitle>
        <p>Kontonummeret til din driftskonto er:</p>
        <p css={fonts.font300medium}>{accountNumber}</p>
        <p>
          Nå kan du sette over penger og fylle på kortet. Bruker du Folio-kortet
          og appen, vil bedriftens kjøp ligge ferdig bokført under{' '}
          <OrgLink
            to={pages.accounting.getUrl()}
            css={css`
              color: inherit;
            `}
          >
            Regnskap
          </OrgLink>
          .
        </p>
        <p css={spacing.getSpacing([0], 'margin-bottom')}>
          Lurer du på noe? <ChatButton>Få hjelp på chat</ChatButton>
        </p>
      </HintWithIllustration>
    </Container>
  );
};

export const BeforeFirstCardAccountTransferHint: React.FC = () => {
  const [shouldShowHint] = useAgentHint('beforeFirstCardAccountTransferAdmin');

  if (!shouldShowHint) {
    return null;
  }

  return (
    <Container>
      <HintWithIllustration illustration={<MoneyToWhereIllustration />}>
        <HintBoxTitle>Velg saldo på kortet ditt</HintBoxTitle>
        <p css={spacing.getSpacing([0], 'margin-bottom')}>
          Du har penger i Folio, men ikke på kortet! Velg hvor mye du vil ha på
          kortet, så er du klar.
        </p>
      </HintWithIllustration>
    </Container>
  );
};

type Card = NonNullable<
  NonNullable<FirstPaintQuery['me']['cardAccount']>['notActivatedCard']
>;

const ActivateCardModalInput: React.FC<{
  card: Card;
}> = ({ card }) => {
  const [errored, setErrored] = React.useState(false);
  const [lookupPin, { data, error, loading: loadingPin }] = useLazyQuery(
    LookupCardPinDocument,
  );
  const [activateCard, { loading: loadingActivation }] = useMutation(
    ActivateCardDocument,
    {
      variables: { cardFid: card.fid },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: FirstPaintDocument }],
    },
  );

  const hasPin = data?.lookupCardPin != null;

  React.useEffect(() => {
    if (hasPin) {
      activateCard();
    }
  }, [activateCard, hasPin]);

  React.useEffect(() => {
    if (error) {
      Sentry.addBreadcrumb({
        message: 'got error sending encrypted data to bff',
      });
      Sentry.captureException(error);
    }
    setErrored(error != null);
  }, [error]);

  const onChange = React.useCallback(
    (cvv: string) => {
      Sentry.addBreadcrumb({ message: 'encrypting pin data' });
      try {
        const encryptedData = encryptPinReadData(
          yearAndMonthToExpiryDate(card.expirationYear, card.expirationMonth),
          cvv,
        );
        setErrored(false);
        lookupPin({ variables: { cardFid: card.fid, encryptedData } });
      } catch (error) {
        Sentry.addBreadcrumb({ message: 'got error encrypting pin data' });
        Sentry.captureException(error);
        setErrored(true);
      }
    },
    [card, lookupPin],
  );

  return (
    <>
      <h3 css={dialogHeadingStyle}>Aktivere kort</h3>
      <p>
        Finn «<span lang="en">Security Code</span>» på baksiden av det nye
        kortet og tast inn her.
      </p>
      <CvvInput
        errored={errored}
        locked={loadingPin || loadingActivation}
        onChange={onChange}
      />
    </>
  );
};

type CardActivationState =
  // When the customer is being onboarded. We have ordered a card for them just
  // now. There is no chance that they have received it yet
  | 'first-card-not-activated-and-ordered-today'
  // When the customer is being onboarded. We have ordered a card for them and
  // it may have arrived
  | 'first-card-not-activated-and-ordered-earlier'
  // The customer already has a card, but also an unactivated card. This means
  // The old card is being replaced due to expiring or because it's been
  // reported as lost.
  | 'replacement-card-not-activated'
  // There's nothing to inform the user about
  | 'no-pending-activation';

/**
 * Renders information box about how to activate a card if the user either has
 * been sent a card because they're a new customer, or because they've been
 * sent a replacement card due to expiry or loss.
 * not actually a hint, but styled like one
 */
export const ActivateCard: React.FC = () => {
  const { data } = useFirstPaintData();
  const [setCardActivated, { called, loading }] = useMutation(
    ActivateCardDocument,
    {
      awaitRefetchQueries: false,
      refetchQueries: [{ query: FirstPaintDocument }],
    },
  );
  const [activateModalOpen, setActivateModalOpen] = React.useState(false);
  const hasFikenDirectIntegration = useHasFikenDirectIntegration();
  const [cardActivationState, setCardActivationState] =
    React.useState<CardActivationState>('no-pending-activation');

  if (called && !loading) {
    return (
      <SmallHintWithIllustration illustration={<MicroCardletterIllustration />}>
        Yes! Kortet er klart til bruk
      </SmallHintWithIllustration>
    );
  }

  const cardAccount = data?.me.cardAccount;
  const notActivatedCard = cardAccount?.notActivatedCard;
  const activeCard = cardAccount?.card;

  // We don't mess with the card activation state if the modal is open, as that
  // might cause the modal container to not render.
  if (!activateModalOpen) {
    let newState: CardActivationState = 'no-pending-activation';

    if (
      activeCard == null &&
      notActivatedCard?.orderedAt &&
      isToday(new Date(notActivatedCard.orderedAt))
    ) {
      newState = 'first-card-not-activated-and-ordered-today';
    } else if (activeCard == null && notActivatedCard != null) {
      newState = 'first-card-not-activated-and-ordered-earlier';
    } else if (activeCard != null && notActivatedCard != null) {
      newState = 'replacement-card-not-activated';
    }

    if (newState !== cardActivationState) {
      setCardActivationState(newState);
    }
  }

  switch (cardActivationState) {
    case 'no-pending-activation':
      return null;

    case 'first-card-not-activated-and-ordered-today':
      return (
        <SmallHintWithIllustration
          illustration={<MicroCardletterIllustration />}
        >
          <div>Ditt geniale bedriftskort er på&nbsp;vei!</div>
          {hasFikenDirectIntegration ? (
            <a
              href="https://folio.no/les/bruk-folio-kortet-med-fiken"
              target="_blank"
              css={css`
                color: inherit;
              `}
            >
              Sånn funker det med Fiken
            </a>
          ) : (
            <a
              href="https://folio.no/kort"
              target="_blank"
              css={css`
                color: inherit;
              `}
            >
              Les om kortet
            </a>
          )}
        </SmallHintWithIllustration>
      );

    case 'first-card-not-activated-and-ordered-earlier':
      return (
        <SmallHintWithIllustration
          illustration={<MicroCardletterIllustration />}
        >
          <div>Har du fått kortet i posten?</div>
          <LinkButton
            css={css`
              color: inherit;
            `}
            onClick={() =>
              setCardActivated({
                variables: {
                  cardFid: invariantValue(
                    notActivatedCard,
                    "If no active card, there's always an inactive one",
                  ).fid,
                },
              })
            }
          >
            Trykk her når du har fått det
          </LinkButton>
        </SmallHintWithIllustration>
      );

    case 'replacement-card-not-activated':
      return (
        <>
          <SmallHintWithIllustration
            illustration={<MicroCardletterIllustration />}
          >
            <h4>Har du fått nytt kort ennå?</h4>
            <p>Det er låst til du aktiverer det.</p>
            <p>
              <LinkButton
                css={css`
                  color: inherit;
                `}
                onClick={() => setActivateModalOpen(true)}
              >
                Aktiver kortet
              </LinkButton>
            </p>
          </SmallHintWithIllustration>
          {activateModalOpen ? (
            <OverlayContainer>
              <ModalDialog
                title={
                  notActivatedCard ? 'Aktivere kort' : 'Kortet er aktivert'
                }
                isOpen={activateModalOpen}
                onClose={() => setActivateModalOpen(false)}
                isKeyboardDismissDisabled={false}
                shouldCloseOnInteractOutside={() => true}
              >
                {notActivatedCard ? (
                  <ActivateCardModalInput card={notActivatedCard} />
                ) : (
                  <div>
                    <div css={dialogHeadingStyle}>Kortet er aktivert</div>
                    <SuccessMarkIllustration />
                    <p>Det gamle kortet bør ødelegges!</p>
                    <p>PIN-koden er den samme som før.</p>
                    <Button
                      fullWidth={true}
                      onClick={() => setActivateModalOpen(false)}
                    >
                      OK
                    </Button>
                  </div>
                )}
              </ModalDialog>
            </OverlayContainer>
          ) : null}
        </>
      );
  }
};

export const FoundingIntroHint: React.FC = () => {
  const [shouldShowHint, dismissCallback] = useAgentHint('foundingIntro');
  const overlayTriggerState = useOverlayTriggerState({
    defaultOpen: shouldShowHint,
    onOpenChange(isOpen) {
      if (!isOpen) {
        dismissCallback();
      }
    },
  });
  const hasFikenDirectIntegration = useHasFikenDirectIntegration();

  if (!overlayTriggerState.isOpen) {
    return null;
  }

  return (
    <OverlayContainer>
      <ModalDialog
        title="Kontoen er åpen!"
        isOpen={overlayTriggerState.isOpen}
        transition={false}
        css={css`
          max-width: 600px;
        `}
      >
        <div
          css={css`
            text-align: end;
          `}
        >
          <PlainButton
            aria-label="Lukk"
            onClick={() => overlayTriggerState.close()}
          >
            <XForCloseIcon
              css={css`
                display: block;
              `}
            />
          </PlainButton>
        </div>
        <EmptyChestIllustration />
        <div
          css={css`
            ${dialogHeadingStyle};
            ${spacing.getSpacing([8], 'margin-top')};
          `}
        >
          Kontoen er åpen!
        </div>
        <p>
          {hasFikenDirectIntegration ? (
            <>Du kan styre det meste fra Fiken, men pengene ligger her.</>
          ) : (
            <>Har du spørsmål? Spør oss på chatten nede til høyre.</>
          )}
        </p>
        <Button
          onClick={() => overlayTriggerState.close()}
          fullWidth
          size="large"
        >
          Sjekk ut kontoen
        </Button>
      </ModalDialog>
    </OverlayContainer>
  );
};

export const AfterAccountantInvitationApprovedHint: React.FC<{
  displayName: string;
}> = ({ displayName }) => {
  const navigate = useOrgNavigate();
  const [shouldShowHint, dismissCallback] = useAgentHint(
    'afterAccountantInvitationApproved',
  );

  React.useEffect(() => {
    pages.accountingSystemSelector.loadableComponent.preload();
  }, []);

  if (!shouldShowHint) {
    return null;
  }

  return (
    <div css={spacing.getSpacing([24, 32], 'margin-bottom')}>
      <HintWithIllustration illustration={<ArigatouIllustration />}>
        <div css={spacing.getSpacing([16], 'gap')}>
          <h2
            css={css`
              ${fonts.font500bold};
              margin: 0;
            `}
          >
            Hei, regnskaps&shy;fører!
          </h2>
          <p>
            Denne kontoen er koblet til <b>{displayName}</b>. Trykk Send når en
            måned er ferdigstilt her, så vil bilagene dukke opp der. Du kan
            gjøre endringer i Innstillinger nedenfor, eller koble bedriften til
            et annet regnskapssystem.
          </p>
          <div
            css={css`
              display: grid;
              grid-template-columns: max-content max-content;
              ${spacing.getSpacing([8], 'gap')};
            `}
          >
            <Button onClick={() => dismissCallback()}>Den er grei</Button>

            <Button
              level="secondary"
              onClick={() => {
                dismissCallback();
                navigate(pages.accountingSystemSelector.getUrl());
              }}
            >
              Bytt system
            </Button>
          </div>
        </div>
      </HintWithIllustration>
    </div>
  );
};

export const PaymentsFromAccountingSystemHint: React.FC = () => {
  const shouldShowHint = useIsAgentHintActive('paymentsFromAccountingSystem');
  const hasFikenDirectIntegration = useHasFikenDirectIntegration();

  if (!shouldShowHint) {
    return null;
  }

  return (
    <SmallHintWithIllustration illustration={<MicroLightbulbIllustration />}>
      {hasFikenDirectIntegration ? (
        <div>
          Når du registrerer en regning i Fiken, kan du sende den hit og betale
          med det samme.
        </div>
      ) : (
        <div>
          Betal her eller fra regnskaps&shy;systemet.{' '}
          <a
            href="https://folio.no/koble-til-regnskapssystemet"
            target="_blank"
            css={css`
              color: inherit;
            `}
          >
            Se&nbsp;muligheter
          </a>
        </div>
      )}
    </SmallHintWithIllustration>
  );
};

export const NewFikenIntegrationHint: React.FC = () => {
  const [shouldShowHint] = useAgentHint('newFikenIntegration');
  const { data } = useFirstPaintData();
  const mediumMediaQuery = spacing.mediumMq;
  const isWide = useMediaLayout(mediumMediaQuery);

  if (!shouldShowHint) {
    return null;
  }

  return (
    <div css={spacing.getSpacing([24, 32], 'margin-bottom')}>
      <HintWithIllustration illustration={<MarieKondoIllustration />}>
        <h2
          css={css`
            ${fonts.font500bold};
            margin: 0;
          `}
        >
          Koble til Fiken på nytt!
        </h2>
        <p>
          Vår nye superintegrasjon sender alt fra Folio til Fiken i sanntid! Du
          bruker en utdatert versjon, og det tar bare noen sekunder å koble opp
          på nytt.
        </p>
        <div
          css={css`
            display: grid;
            ${spacing.getSpacing([16], 'gap')};
            justify-items: center;

            @media (${mediumMediaQuery}) {
              grid-template-columns: max-content max-content;
              align-items: baseline;
            }
          `}
        >
          <ButtonLink
            size={isWide ? 'medium' : 'large'}
            href={makeFikenDeepLink(
              'foliomodul',
              data?.organization.orgNum,
              isProd(),
            )}
            fullWidth={!isWide}
            target="_blank"
          >
            Koble til gratis
          </ButtonLink>{' '}
        </div>
      </HintWithIllustration>
    </div>
  );
};
