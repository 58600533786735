import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, fonts, spacing } from 'folio-common-components';
import { folioEmailAddress, folioEmailAddressUrl } from 'folio-common-utils';
import * as React from 'react';
import { DismissAgentHintDocument } from '../../../common-client-queries.generated';
import { NarrowPage } from '../../../components/NarrowPage';
import { ChatButton } from '../../../components/chat-button';
import {
  CardArrivalIllustration,
  CashbagIllustration,
  TransformedIllustration,
} from '../../../illustrations';

const Container = styled(NarrowPage)`
  text-align: center;
`;

const Section = styled.div`
  ${spacing.getSpacing([16], 'margin-bottom')};
`;

const Heading = styled.h1`
  ${fonts.font600black};
  margin: 0;
`;

const SubHeading = styled.h2`
  ${fonts.font500bold};
  margin: 0 0 8px 0;
`;

const illustrationMargin = css`
  margin: 64px 0 24px 0;
`;

export const FoundingSplash: React.FC = () => {
  const [dismissMutation, { called, loading }] = useMutation(
    DismissAgentHintDocument,
    { variables: { agentHintKind: 'afterFoundingActivation' } },
  );

  return (
    <Container>
      <Section>
        <Heading>Velkommen!</Heading>
        <p>Nå har du Folio. Dette skjer nå:</p>
      </Section>

      <Section>
        <CashbagIllustration css={illustrationMargin} />
        <SubHeading>Aksjekapitalen kan brukes</SubHeading>
        <p>
          Pengene står på driftskonto og tilhører bedriften. Bruk av dem når du
          skal betale gebyret fra Brønnøysundregistrene!
        </p>
      </Section>

      <Section>
        <CardArrivalIllustration css={illustrationMargin} />
        <SubHeading>Du får et Folio-kort i posten</SubHeading>
        <p>
          Vi har laget et smart bedriftskort som gjør alle kjøp og kvitteringer
          klare til regnskapet. Prøv det og se!
        </p>
      </Section>

      <Section>
        <TransformedIllustration css={illustrationMargin} />
        <SubHeading>Vi forbedrer Folio hver dag</SubHeading>
        <p>
          Folio er under utvikling. Fortell oss hva du ønsker deg eller lurer
          på! Spør <a href={folioEmailAddressUrl}>{folioEmailAddress}</a> eller
          trykk på <ChatButton>chatten</ChatButton> nede til høyre når du er
          innlogget på Folio.no.
        </p>
      </Section>

      <Button
        css={css`
          margin-top: 24px;
        `}
        loading={loading}
        onClick={() => {
          if (called || loading) {
            return;
          }
          return dismissMutation();
        }}
      >
        Vis meg driftskontoen min
      </Button>
    </Container>
  );
};
