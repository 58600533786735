import * as React from 'react';
export const LogoIconLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={28}
    height={34}
    fill="none"
    viewBox="0 0 28 34"
    aria-hidden="true"
    {...props}
  >
    <path fill="#fff" d="M19 9v24H1V5a4 4 0 0 1 4-4" />
    <path
      stroke="#000"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M19 9v24H1V5a4 4 0 0 1 4-4"
    />
    <path
      stroke="#000"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M1 19a3.9998 3.9998 0 0 1 4-4"
    />
    <path
      fill="#BDB2F5"
      stroke="#000"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M27 9H9V5a4 4 0 0 0-4-4h18a3.9998 3.9998 0 0 1 4 4v4Z"
    />
    <path
      fill="#FDC7BD"
      stroke="#000"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M27 23H9v-4a3.9998 3.9998 0 0 0-4-4h18a3.9996 3.9996 0 0 1 2.8284 1.1716A3.9996 3.9996 0 0 1 27 19v4Z"
    />
  </svg>
);
