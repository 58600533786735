import { css } from '@emotion/react';
import { spacing } from 'folio-common-components';
import * as React from 'react';
import { useMediaLayout } from 'use-media';
import type {
  FirstPaintNoOrgQuery,
  FirstPaintQuery,
} from '../../common-queries.generated';
import type { MenuItem } from '../../hooks/use-menu-items';
import { ChatIcon, LeversVerticalIcon, SmileyIcon } from '../../icons';
import { pages } from '../../pages';
import { LargeHeaderBar } from '../large-header-bar';
import { NavAvatarBox } from '../nav-avatar-box';
import { NavMenuContent } from '../nav-menu-content';
import { SmallHeaderBar } from '../small-header-bar';

export interface Props {
  readonly showProfileAndSettings: boolean;
  readonly menuItems: readonly MenuItem[];
  readonly agent: Pick<FirstPaintQuery['me'], 'structuredName' | 'avatarId'>;
  readonly organization?: Pick<
    FirstPaintQuery['organization'],
    'name' | 'orgNum'
  >;
  readonly orgs: readonly Pick<
    FirstPaintNoOrgQuery['organizations']['availableOrgs'][number],
    'name' | 'orgNum' | 'state' | 'isInSession'
  >[];
}

export const HeaderBar: React.FC<Props> = props => {
  const isWide = useMediaLayout(spacing.extraLargeMq);
  const { menuItems, organization, agent, orgs, showProfileAndSettings } =
    props;

  const otherOrgs = orgs
    .filter(item => item.orgNum !== organization?.orgNum)
    .filter(e => e.state !== 'Rejected');

  let navItems = isWide ? [] : menuItems;

  if (showProfileAndSettings) {
    navItems = [
      ...navItems,
      {
        page: pages.profile,
        icon: <SmileyIcon />,
        labelComponent: () => <>Min profil</>,
      },
      {
        page: pages.orgSettings,
        icon: <LeversVerticalIcon />,
        labelComponent: () => <>Konto</>,
      },
      {
        page: pages.customerService,
        icon: <ChatIcon />,
        labelComponent: () => <>Kundeservice</>,
      },
    ];
  }

  if (isWide) {
    return (
      <LargeHeaderBar
        name={agent.structuredName.full}
        avatarId={agent.avatarId}
        orgName={organization?.name}
        menuContent={(isOpen, closeMenu) => (
          <NavMenuContent
            menuItems={navItems}
            orgItems={otherOrgs}
            isVisible={isOpen}
            closeMenu={closeMenu}
          />
        )}
      />
    );
  } else {
    return (
      <SmallHeaderBar
        orgName={organization?.name}
        menuContent={(isOpen, closeMenu) => (
          <>
            <div
              css={css`
                ${spacing.getSpacing([16], 'padding-top', 'padding-left')};
                touch-action: none; /* support tap and slide to menu items */
              `}
              onPointerDown={event => {
                if (event.target instanceof Element) {
                  event.target.releasePointerCapture(event.pointerId);
                }
                event.stopPropagation();
              }}
            >
              <NavAvatarBox
                avatarPosition="left"
                name={agent.structuredName.full}
                avatarId={agent.avatarId}
                orgName={organization?.name}
              />
            </div>
            <NavMenuContent
              menuItems={navItems}
              orgItems={otherOrgs}
              isVisible={isOpen}
              closeMenu={closeMenu}
            />
          </>
        )}
      />
    );
  }
};
