import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fonts, spacing } from 'folio-common-components';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import type { FirstPaintNoSessionQuery } from '../../common-queries.generated';
import type { BroadcastScope, BroadcastSeverity } from '../../gqltypes';
import { useFirstPaintNoSessionData } from '../../hooks/use-first-paint-data';

export const BroadcastBoxWrapper = styled.div`
  ${spacing.getSpacing([16], 'margin-bottom')};
`;

export const broadcastColor: Record<BroadcastSeverity, string> = {
  Error: colors.redLightOpaque,
  Warning: colors.yellowLightOpaque,
  Info: colors.blueLightOpaque,
};

export const BroadcastWrapper = styled.div<{ severity: BroadcastSeverity }>`
  ${spacing.getSpacing([32], 'padding')};
  ${spacing.getSpacing([32, 40], 'padding-vertical')};
  background-color: ${({ severity }) => broadcastColor[severity]};
  border-radius: 8px;
`;

export const BroadcastBoxHeading = styled.h2`
  margin: 0;
  ${fonts.font300demi};
  ${spacing.getSpacing([8], 'margin-bottom')};
`;

export const BroadcastBox: React.FC<{
  scopes: readonly BroadcastScope[];
}> = ({ scopes }) => {
  const { data, loading } = useFirstPaintNoSessionData();

  if (loading || !data) {
    return null;
  }

  const broadcastMessages = data.broadcast;

  const broadcastMessagesToShow =
    scopes.length > 0
      ? broadcastMessages.filter(mess =>
          mess.scopes.some(scope => scopes.includes(scope)),
        )
      : broadcastMessages;

  if (broadcastMessagesToShow.length === 0) {
    return null;
  }

  return (
    <>
      {broadcastMessagesToShow.map(broadcast => (
        <BroadcastBoxContent key={broadcast.fid} broadcast={broadcast} />
      ))}
    </>
  );
};

export type Broadcast = Omit<
  FirstPaintNoSessionQuery['broadcast'][number],
  '__typename' | 'fid'
>;

const BroadcastContentBoxBase: React.FC<{ broadcast: Broadcast }> = ({
  broadcast,
}) => (
  <BroadcastBoxWrapper>
    <BroadcastWrapper severity={broadcast.severity}>
      {broadcast.heading && (
        <BroadcastBoxHeading>{broadcast.heading}</BroadcastBoxHeading>
      )}
      <div
        css={css`
          p:first-of-type {
            margin-top: 0;
          }
          p:last-of-type {
            margin-bottom: 0;
          }
        `}
        dangerouslySetInnerHTML={{ __html: broadcast.message }}
      />
    </BroadcastWrapper>
  </BroadcastBoxWrapper>
);

export const BroadcastBoxContent = React.memo(BroadcastContentBoxBase);
