import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Divider as BaseDivider, spacing } from 'folio-common-components';
import * as React from 'react';

export const EventContentWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <div
    css={css`
      display: grid;
      ${spacing.getSpacing([32], 'gap', 'padding-bottom')};
      ${spacing.getSpacing([16], 'padding-horizontal')};
    `}
  >
    {children}
  </div>
);

export const Divider = styled(BaseDivider)`
  margin: 0;
`;
