import { css } from '@emotion/react';
import { spacing } from 'folio-common-components';
import * as React from 'react';

export const ListStatusMessage: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <div
      css={css`
        color: var(--muted-color);
        text-align: center;
        ${spacing.getSpacing([104], 'margin-top')};
      `}
    >
      {children}
    </div>
  );
};
