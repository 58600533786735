import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import { promotions } from 'bank-common';
import { useBatchedQuery } from 'bank-common-client';
import {
  Button,
  DummyButton,
  fonts,
  noChildVerticalMargins,
  spacing,
} from 'folio-common-components';
import { invariant } from 'folio-common-utils';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaLayout } from 'use-media';
import type { FirstPaintQuery } from '../../common-queries.generated';
import type { PromotionState } from '../../gqltypes';
import { useDocumentIsVisible } from '../../hooks/document-visible';
import { useFirstPaintData } from '../../hooks/use-first-paint-data';
import { useSearchParams } from '../../hooks/use-query-arg';
import { CheckForYesIcon } from '../../icons';
import {
  DrinkIllustration,
  FishyIllustration,
  GolferIllustration,
  OhNoIllustration,
  SecuritySweatIllustration,
  TreasureChestIllustration,
  UnicornSupercleanIllustration,
} from '../../illustrations';
import { SetSpvClearanceDocument } from '../../queries.generated';
import { InProgressView } from '../InProgress';
import { InfoPage } from '../info-page';
import { LoginInAgainButton } from '../login-for-spv-clearance';
import {
  DismissPromotionInfoDocument,
  OwnPromotionAfterSigningDocument,
  OwnPromotionWaitingForApprovalDocument,
} from './queries.generated';

type Promotion = NonNullable<FirstPaintQuery['me']['latestPromotion']>;

const Container = styled.div`
  ${spacing.getSpacing([16], 'margin-bottom')};
  border-radius: 8px;
  position: relative;
  ${spacing.getSpacing([16, 32], 'padding')};
`;

const SigningBoxContent = styled.div`
  ${spacing.getSpacing([16], 'margin-top')};
`;

const DismissButton: React.FC<{
  fid: string;
  isDismissed: boolean;
  onDismiss: () => void;
}> = ({ fid, isDismissed, onDismiss }) => {
  const [dismiss] = useMutation(DismissPromotionInfoDocument, {
    variables: { fid },
  });
  const documentIsVisible = useDocumentIsVisible();

  React.useEffect(() => {
    if (isDismissed) {
      return;
    }
    const timeout = setTimeout(() => {
      if (documentIsVisible) {
        dismiss();
      }
    }, 10_000);

    return () => {
      clearTimeout(timeout);
    };
  }, [dismiss, documentIsVisible, isDismissed]);

  return (
    <SigningBoxContent>
      <Button
        icon={<CheckForYesIcon />}
        onClick={() => {
          onDismiss();
          // this might have been called by a timer, in which case we don't
          // need to call it again
          if (!isDismissed) {
            dismiss();
          }
        }}
      >
        Skjønner
      </Button>
    </SigningBoxContent>
  );
};

const Explanations: React.FC<{
  promotion: Promotion;
  orgName: string;
  hasIllustration: boolean;
  needsToLogIn: boolean;
}> = ({ promotion, orgName, hasIllustration, needsToLogIn }) => {
  const explanation = promotions.getExplanation(promotion, needsToLogIn);

  return (
    <>
      <div
        css={css`
          ${hasIllustration ? fonts.font500bold : fonts.font300demi};
          ${spacing.getSpacing([8], 'margin-bottom')};
        `}
      >
        {explanation.heading}
      </div>
      <p
        css={css`
          margin: 0;
        `}
      >
        {explanation.paragraph.replace(/{ORG_NAME}/g, orgName)}
      </p>
    </>
  );
};

const IllustrationWrapper = styled.div`
  text-align: center;
  /* compensate for padding */
  ${spacing.getSpacing([16], 'margin-vertical')};

  @media ${spacing.mediumMq} {
    margin: initial;
  }
`;

const Illustration: React.FC<{
  promotion: Promotion;
  needsToLogIn: boolean;
}> = ({ promotion, needsToLogIn }) => {
  if (needsToLogIn) {
    return (
      <IllustrationWrapper>
        <SecuritySweatIllustration />
      </IllustrationWrapper>
    );
  }

  if (promotion.state === 'Done') {
    return promotion.toType === 'Administrator' ? (
      <IllustrationWrapper>
        <DrinkIllustration />
      </IllustrationWrapper>
    ) : (
      <IllustrationWrapper>
        {promotion.fromType == null ? (
          <UnicornSupercleanIllustration />
        ) : (
          <GolferIllustration />
        )}
      </IllustrationWrapper>
    );
  }

  if (
    promotion.state === 'Approval' &&
    promotion.fromType == null &&
    promotion.toType === 'Administrator'
  ) {
    return (
      <IllustrationWrapper>
        <FishyIllustration />
      </IllustrationWrapper>
    );
  }

  return (
    <IllustrationWrapper>
      <TreasureChestIllustration />
    </IllustrationWrapper>
  );
};

const SignWithSeamlessSpvClearance: React.FC<{
  signingUrl: string;
  needsToLogIn: boolean;
}> = ({ signingUrl, needsToLogIn }) => {
  invariant(signingUrl, 'State is `AgentSigning`, but no signing URL exists');

  const [forceLoading, setForceLoading] = React.useState(false);
  const [setSpvClearance, { loading, error }] = useMutation(
    SetSpvClearanceDocument,
  );

  if (needsToLogIn) {
    return (
      <SigningBoxContent>
        <LoginInAgainButton />
      </SigningBoxContent>
    );
  }

  return (
    <SigningBoxContent>
      <Button
        onClick={async () => {
          setForceLoading(true);
          try {
            await setSpvClearance();

            window.location.assign(signingUrl);
          } catch {
            setForceLoading(false);
          }
        }}
        loading={loading || forceLoading}
      >
        Gå til bankavtale
      </Button>
      {error ? (
        <p
          css={css`
            color: ${colors.wcagRed};
          `}
        >
          Det funker ikke! Vent litt, vi er på saken.
        </p>
      ) : null}
    </SigningBoxContent>
  );
};

const SignPromotion: React.FC<{
  signing: Promotion['signing'];
  needsToGetSpvClearance: boolean;
  needsToLogIn: boolean;
}> = ({ signing, needsToGetSpvClearance, needsToLogIn }) => {
  const signingUrl = signing?.url;
  invariant(signingUrl, 'State is `AgentSigning`, but no signing URL exists');

  if (needsToGetSpvClearance) {
    return (
      <SignWithSeamlessSpvClearance
        signingUrl={signingUrl}
        needsToLogIn={needsToLogIn}
      />
    );
  }

  return (
    <SigningBoxContent>
      <a href={signingUrl}>
        <DummyButton>Gå til bankavtale</DummyButton>
      </a>
    </SigningBoxContent>
  );
};

const promotionJustSignedQuery = 'rolleendring-signert';
const promotionSigningAbortedQuery = 'rolleendring-signering-avbrutt';
const promotionSigningFailedQuery = 'rolleendring-signering-feilet';
const onboardingJustSignedQuery = 'kollega-signert';
const onboardingSigningAbortedQuery = 'kollega-signering-avbrutt';
const onboardingSigningFailedQuery = 'kollega-signering-feilet';

export function useJustSignedPromotion() {
  const searchParams = useSearchParams();

  return React.useMemo(() => {
    const justSigned =
      searchParams.has(promotionJustSignedQuery) ||
      searchParams.has(onboardingJustSignedQuery);
    const signingAborted =
      searchParams.has(promotionSigningAbortedQuery) ||
      searchParams.has(onboardingSigningAbortedQuery);
    const signingFailed =
      searchParams.has(promotionSigningFailedQuery) ||
      searchParams.has(onboardingSigningFailedQuery);

    return {
      any: justSigned || signingFailed || signingAborted,
      justSigned,
      signingAborted,
      signingFailed,
    };
  }, [searchParams]);
}

function usePollAfterSigning(
  justSigned: boolean,
  promotionState: PromotionState,
) {
  const navigate = useNavigate();
  const { search } = useLocation();

  useBatchedQuery(OwnPromotionAfterSigningDocument, {
    pollInterval: 500,
    skip: !justSigned,
  });

  React.useEffect(() => {
    // if we don't have the query or the state is AgentSigning, do nothing
    if (!justSigned || promotionState === 'AgentSigning') {
      return;
    }

    // when we are no longer in `AgentSigning`, the changed state triggers a
    // refetch of first paint data. waiting a tiny bit avoids a flash of no content
    const timeout = setTimeout(() => {
      // does not use `useSearchParams` as we mutate the params
      const params = new URLSearchParams(search);
      params.delete(promotionJustSignedQuery);
      params.delete(onboardingJustSignedQuery);
      const paramString = params.toString();
      navigate(paramString ? '' : `?${paramString}`, { replace: true });
    }, 250);

    return () => {
      clearTimeout(timeout);
    };
  }, [justSigned, navigate, promotionState, search]);
}

const SigningFailedOrAborted: React.FC<{ promotion: Promotion }> = ({
  promotion,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const removeQueries = React.useCallback(() => {
    const params = new URLSearchParams(search);

    params.delete(promotionSigningAbortedQuery);
    params.delete(promotionSigningFailedQuery);
    params.delete(onboardingSigningAbortedQuery);
    params.delete(onboardingSigningFailedQuery);

    const paramString = params.toString();

    return navigate(paramString ? '' : `?${paramString}`, {
      replace: true,
    });
  }, [navigate, search]);

  const signingUrl =
    promotion.state === 'AgentSigning'
      ? promotion.signing?.url ?? undefined
      : undefined;

  React.useEffect(() => {
    if (!signingUrl) {
      Sentry.captureMessage(
        'Signing was failed or aborted, but there is no signingUrl',
      );
      removeQueries();
    }
  }, [removeQueries, signingUrl]);

  return (
    <InfoPage
      illustration={<OhNoIllustration />}
      heading="Signaturen vil ikke!"
      body={
        <div>
          <p>
            Kanskje teknologien sviktet, eller kanskje feil person signerte? Vi
            mangler fortsatt signatur på avtalen.
          </p>
          <div>
            <a href={signingUrl}>
              <DummyButton
                css={css`
                  width: 165px;
                  ${spacing.getSpacing([32, 32, 40], 'margin-right')};
                `}
              >
                Prøv igjen
              </DummyButton>
            </a>
            <Button
              css={css`
                width: 165px;
              `}
              onClick={() => removeQueries()}
              level="secondary"
            >
              Ikke nå
            </Button>
          </div>
        </div>
      }
    />
  );
};

const View: React.FC<{ promotion: Promotion; orgName: string }> = ({
  promotion,
  orgName,
}) => {
  const { justSigned, signingAborted, signingFailed } =
    useJustSignedPromotion();
  const { data: firstPaintData } = useFirstPaintData();
  // every 5 minutes, poll it. if the promotion is approved, that'll
  // change the state, and stop this polling
  useBatchedQuery(OwnPromotionWaitingForApprovalDocument, {
    pollInterval: 5 * 1_000 * 60,
    skip: promotion.state !== 'Approval',
  });

  usePollAfterSigning(justSigned, promotion.state);

  const isDismissed = promotion.showInfoAboutChanges === false;

  // this is only set the first time, so when `showInfoAboutChanges` changes,
  // `hidden` will stay the same until set manually
  const [hidden, setHidden] = React.useState(isDismissed);

  const isBig = useMediaLayout(spacing.mediumMq);

  if (hidden || firstPaintData == null) {
    return null;
  }

  if (justSigned) {
    return <InProgressView />;
  }

  if (signingAborted || signingFailed) {
    return <SigningFailedOrAborted promotion={promotion} />;
  }

  if (promotion.toType === 'ExternalAccountant') {
    return null;
  }

  const promotionIsDone = promotion.state === 'Done';
  const promotionNeedsToBeSigned = promotion.state === 'AgentSigning';

  const needsToGetSpvClearance =
    firstPaintData.me.spvClearance === false &&
    (promotion.toType === 'ExternalPaymentAccountant' ||
      // CFO and admin should have gone through clearance as part of their
      // onboarding, but who knows! Better safe than sorry.
      promotion.toType === 'CFO' ||
      promotion.toType === 'Administrator');
  const needsToLogIn =
    needsToGetSpvClearance && !firstPaintData.me.hasCertificateInfoInSession;

  const hasIllustration =
    promotion.state !== 'Approval' ||
    promotion.fromType == null ||
    needsToLogIn;

  return (
    <Container
      css={css`
        background-color: ${promotionIsDone &&
        promotion.fromType != null &&
        promotion.toType !== 'Administrator'
          ? colors.greenLightOpaque
          : colors.blueLightOpaque};
      `}
    >
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr auto;
          align-items: center;
        `}
      >
        {isBig ? (
          <>
            <div
              css={css`
                ${spacing.getSpacing([32, 32, 40], 'padding-right')};
                ${noChildVerticalMargins};
                align-self: center;
              `}
            >
              <Explanations
                promotion={promotion}
                orgName={orgName}
                hasIllustration={hasIllustration}
                needsToLogIn={needsToLogIn}
              />
              {/* Only completed promotions can be manually dismissed */}
              {promotionIsDone ? (
                <DismissButton
                  fid={promotion.fid}
                  isDismissed={isDismissed}
                  onDismiss={() => setHidden(true)}
                />
              ) : null}
              {promotionNeedsToBeSigned ? (
                <SignPromotion
                  signing={promotion.signing}
                  needsToGetSpvClearance={needsToGetSpvClearance}
                  needsToLogIn={needsToLogIn}
                />
              ) : null}
            </div>
            {hasIllustration ? (
              <Illustration promotion={promotion} needsToLogIn={needsToLogIn} />
            ) : null}
          </>
        ) : (
          <div>
            {hasIllustration ? (
              <Illustration promotion={promotion} needsToLogIn={needsToLogIn} />
            ) : null}
            <Explanations
              promotion={promotion}
              orgName={orgName}
              hasIllustration={hasIllustration}
              needsToLogIn={needsToLogIn}
            />
            {/* Only completed promotions can be manually dismissed */}
            {promotionIsDone ? (
              <DismissButton
                fid={promotion.fid}
                isDismissed={isDismissed}
                onDismiss={() => setHidden(true)}
              />
            ) : null}
            {promotionNeedsToBeSigned ? (
              <SignPromotion
                signing={promotion.signing}
                needsToGetSpvClearance={needsToGetSpvClearance}
                needsToLogIn={needsToLogIn}
              />
            ) : null}
          </div>
        )}
      </div>
    </Container>
  );
};

const unsupportedStates = new Set<PromotionState>([
  'Unspecified',
  'Denied',
  'Aborted',
]);

export const PromotionDisplay: React.FC<{
  promotion: Promotion | null;
  orgName: string;
}> = ({ promotion, orgName }) => {
  const { any } = useJustSignedPromotion();

  if (any) {
    invariant(promotion, 'A promotion was signed, but no promotion exists');
  }

  if (!promotion) {
    return null;
  }

  const { state } = promotion;

  // nothing to show
  if (state === 'Expired' || state === 'WrongPersonSigning') {
    return null;
  }

  // these states don't affect the current user
  if (state === 'NeedsSigning' || state === 'AdminSigning') {
    if (any) {
      throw new Error(`Just signed, but state is ${state}.`);
    }
    return null;
  }

  invariant(!unsupportedStates.has(state), `State ${state} is not handled`);

  return <View promotion={promotion} orgName={orgName} />;
};
