import { useApolloClient, useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { VisuallyHidden } from 'folio-common-components';
import { isArrayOfAtLeastOne, notEmpty, partition } from 'folio-common-utils';
import promiseSettledAggregate from 'promise-settled-aggregate';
import * as React from 'react';
import { lazyWithPreload } from 'react-lazy-with-preload';
import { Navigate } from 'react-router-dom';
import { FirstPaintDocument } from '../../common-queries.generated';
import { BroadcastBox } from '../../components/Broadcast';
import {
  ActivateCard,
  BeforeFirstCardAccountTransferHint,
  BeforeFirstFolioAccountTransferHint,
  FoundingIntroHint,
} from '../../components/hints';
import { InfoPage } from '../../components/info-page';
import { ListStatusMessage } from '../../components/list-status-message';
import { useOrgNavigate } from '../../components/org-navigation';
import { PageHeading } from '../../components/page-heading';
import {
  useEventFilters,
  useSelectedPersonFromUrl,
} from '../../event-filter-context';
import { usePollQueryIfDocumentIsVisible } from '../../hooks/document-visible';
import { useChangedEvents } from '../../hooks/use-changed-events';
import { useFirstPaintData } from '../../hooks/use-first-paint-data';
import { useQueryArg } from '../../hooks/use-query-arg';
import { useTitle } from '../../hooks/use-title';
import {
  FishyIllustration,
  TreasureChestIllustration,
} from '../../illustrations';
import { pages } from '../../pages';
import { PauseFish } from './PauseFish';
import { TransactionHeading } from './TransactionHeading';
import { TransactionsView } from './TransactionsView';
import { FoundingSplash } from './onboarding-status/FoundingSplash';
import { InProgressView } from './onboarding-status/shared';
import {
  GetNewEventsAndBalanceDocument,
  TransactionPageDataDocument,
} from './queries.generated';
import * as transactionsPageUtils from './transactions-page-utils';

const OnboardingStateScreen = lazyWithPreload(
  () => import('./onboarding-status'),
);

type Action =
  | { type: 'next'; cursor: string | null }
  | { type: 'prev'; cursor: string | null }
  | { type: 'load-more'; cursor: string | null; size: number }
  | { type: 'page-size'; size: number }
  | {
      type: 'reset';
      selectedPerson: string;
      fromDate: string | null;
      toDate: string | null;
    };

const reducer: React.Reducer<
  transactionsPageUtils.TransactionsPageState & {
    selectedPerson: string;
    fromDate: string | null;
    toDate: string | null;
  },
  Action
> = (prevState, action) => {
  switch (action.type) {
    case 'next':
      return { ...prevState, after: action.cursor, before: null };
    case 'prev':
      return { ...prevState, after: null, before: action.cursor };
    case 'load-more':
      return {
        ...prevState,
        after: null,
        before: null,
        pageSize: prevState.pageSize + action.size,
      };
    case 'reset':
      return {
        ...prevState,
        after: null,
        before: null,
        selectedPerson: action.selectedPerson,
        fromDate: action.fromDate,
        toDate: action.toDate,
      };
    case 'page-size': {
      return { ...prevState, pageSize: action.size };
    }
  }
};

const tenMinutes = 1000 * 60 * 10;

function useFromDateFromUrl(): string | null {
  return useQueryArg('fraDato') || null;
}

function useToDateFromUrl(): string | null {
  return useQueryArg('tilDato') || null;
}

const TransactionsPage: React.FC = () => {
  useTitle('Oversikt');
  const navigate = useOrgNavigate();

  const client = useApolloClient();
  const { eventFilters } = useEventFilters();
  const urlPersonFilter = useSelectedPersonFromUrl();
  const urlFromDateFilter = useFromDateFromUrl();
  const urlToDateFilter = useToDateFromUrl();
  const [getEvents] = useLazyQuery(GetNewEventsAndBalanceDocument, {
    fetchPolicy: 'network-only',
  });

  React.useEffect(() => {
    const activePersonFilter = eventFilters.person;

    if (activePersonFilter !== urlPersonFilter) {
      const url = pages.transactions.getUrl({ agentFid: activePersonFilter });
      navigate(url, { replace: true });
    }
  }, [eventFilters.person, navigate, urlPersonFilter]);

  const [state, dispatch] = React.useReducer(reducer, {
    ...transactionsPageUtils.defaultState(),
    selectedPerson: eventFilters.person,
    fromDate: urlFromDateFilter,
    toDate: urlToDateFilter,
  });

  const { error, loading, data } = usePollQueryIfDocumentIsVisible(
    TransactionPageDataDocument,
    tenMinutes,
    {
      variables: {
        count: state.pageSize,
        afterCursor: state.after,
        beforeCursor: state.before,
        fromDate: state.fromDate,
        toDate: state.toDate,
        agent: state.selectedPerson,
      },
    },
  );

  const handleChangedEvents = React.useCallback(
    async (eventFids: readonly string[]) => {
      Sentry.addBreadcrumb({
        message: 'Got changed events',
        data: { eventFids },
      });
      try {
        const apolloCache = client.cache.extract();
        const [eventsInCache, eventsNotInCache] = partition(eventFids, fid => {
          const stringifiedKey = JSON.stringify({ fid });
          return (
            Object.hasOwn(apolloCache, `Event:${stringifiedKey}`) ||
            Object.hasOwn(apolloCache, `SalaryPayment:${stringifiedKey}`)
          );
        });

        const promises = [
          isArrayOfAtLeastOne(eventsInCache)
            ? getEvents({ variables: { eventFids: eventsInCache } })
            : null,
          isArrayOfAtLeastOne(eventsNotInCache)
            ? client.refetchQueries({ include: [TransactionPageDataDocument] })
            : // if no new events, refetch first paint to get new balances
              client.refetchQueries({ include: [FirstPaintDocument] }),
        ].filter(notEmpty);

        await promiseSettledAggregate(promises);
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [client, getEvents],
  );

  useChangedEvents(handleChangedEvents);

  const onSelectPerson = React.useCallback((item: string) => {
    dispatch({
      type: 'reset',
      selectedPerson: item,
      toDate: null,
      fromDate: null,
    });
  }, []);

  React.useEffect(() => {
    if (error) {
      Sentry.addBreadcrumb({ message: 'Error fetching transactions' });
      Sentry.captureException(error);
    }
  }, [error]);

  return (
    <>
      <BroadcastBox scopes={['CardTransfers', 'Transactions']} />
      <FoundingIntroHint />
      <ActivateCard />
      <BeforeFirstFolioAccountTransferHint />
      <BeforeFirstCardAccountTransferHint />
      <VisuallyHidden>
        <PageHeading>Oversikt</PageHeading>
      </VisuallyHidden>
      <TransactionHeading />
      {error && !data ? (
        <ListStatusMessage>
          Klarte ikke å hente bevegelsene. Vennligst prøv igjen!
        </ListStatusMessage>
      ) : (
        <TransactionsView
          loading={loading}
          data={data}
          selectedPerson={state.selectedPerson}
          onSelectPerson={onSelectPerson}
          pageSize={state.pageSize}
          onSelectPageSize={size => dispatch({ type: 'page-size', size })}
          onSelectPreviousPage={() => {
            if (data) {
              dispatch({
                type: 'prev',
                cursor: data.events.pageInfo.startCursor,
              });
            }
          }}
          onSelectNextPage={() => {
            if (data) {
              dispatch({
                type: 'next',
                cursor: data.events.pageInfo.endCursor,
              });
            }
          }}
          onSelectLoadMore={() => {
            if (data) {
              dispatch({
                type: 'load-more',
                cursor: data.events.pageInfo.endCursor,
                size: 5000,
              });
            }
          }}
          usesDateFilter={urlFromDateFilter != null || urlToDateFilter != null}
        />
      )}
    </>
  );
};

const MainPage: React.FC = props => {
  const { data: firstPaintData } = useFirstPaintData();
  if (!firstPaintData) {
    return null;
  }
  const { organization, me } = firstPaintData;
  const { name: orgName, state: orgState } = organization;
  const { activeHints, latestPromotion, state: agentState, type } = me;

  if (
    (orgState === 'Active' || orgState === 'ReadyToSignFounding') &&
    type === 'CoFounder' &&
    (latestPromotion == null ||
      latestPromotion.state === 'AdminSigning' ||
      latestPromotion.state === 'NeedsSigning' ||
      latestPromotion.state === 'Expired')
  ) {
    return (
      <InfoPage
        illustration={<TreasureChestIllustration />}
        heading="Spør om å få Folio"
        body={
          <p>
            Finn ut av hvem som administrerer Folio i bedriften, og be om kort
            og tilgang.
          </p>
        }
      />
    );
  }

  // for some reason, the redirect in `useFirstPaintData` doesn't work when deployed
  if (orgState === 'Rejected') {
    return (
      <Navigate
        to="/logg-inn/velg-organisasjon"
        replace={true}
        state={{ rejectedOrgName: orgName }}
      />
    );
  }

  if (type === 'Invitee') {
    return (
      <InfoPage
        illustration={<FishyIllustration />}
        heading="Venter på bekreftelse"
        body={
          <>
            <p>
              Vi har bedt en administrator i {orgName} bekrefte at du er rett
              person.
            </p>
            <p>Vi sender deg en e-post når det er gjort.</p>
          </>
        }
      />
    );
  }

  // TODO: we want to show a "welcome" screen when the org state switches to `Active` for the first time
  if (
    agentState === 'Onboarding' ||
    orgState === 'Onboarding' ||
    (agentState !== 'Active' && latestPromotion?.state === 'AgentSigning')
  ) {
    return (
      <React.Suspense fallback={<InProgressView />}>
        <OnboardingStateScreen orgState={orgState} />
      </React.Suspense>
    );
  }

  if (agentState !== 'Active') {
    return <PauseFish />;
  }

  if (activeHints.some(hint => hint.kind === 'afterFoundingActivation')) {
    return <FoundingSplash />;
  }

  return <TransactionsPage {...props} />;
};

// React.lazy only supports default exports:
// https://reactjs.org/docs/code-splitting.html#named-exports
// eslint-disable-next-line import/no-default-export
export default MainPage;
