import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fonts, spacing } from 'folio-common-components';
import * as React from 'react';
import { NarrowPage } from './NarrowPage';

const Heading = styled.h1`
  ${fonts.font500bold};
  ${spacing.getSpacing([48], 'margin-top')};
  ${spacing.getSpacing([24], 'margin-bottom')};
`;

export const InfoPage: React.FC<{
  illustration: React.ReactElement;
  heading: React.ReactElement | string;
  body: React.ReactNode;
}> = ({ illustration, heading, body, ...rest }) => (
  <div css={spacing.getSpacing([16], 'padding-horizontal')}>
    <NarrowPage
      css={css`
        text-align: center;
        text-wrap: balance;
        ${spacing.getSpacing([80], 'margin-top')};
      `}
      {...rest}
    >
      {illustration}
      <Heading>{heading}</Heading>
      {body}
    </NarrowPage>
  </div>
);
