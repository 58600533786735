import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { links, spacing } from 'folio-common-components';
import { formatters } from 'folio-common-utils';
import * as React from 'react';
import { useMediaLayout } from 'use-media';
import type { FirstPaintQuery } from '../../common-queries.generated';
import { cardStyle } from '../../components/card-style';
import { OrgLink } from '../../components/org-navigation';
import { useFirstPaintData } from '../../hooks/use-first-paint-data';
import { pages } from '../../pages';
import { type AccountSummary, useAccountSummaries } from './summary';
import {
  AmountSpan,
  NarrowAccountSummary,
  WideAccountSummary,
} from './summary-components';

const WideHeadingContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  ${spacing.getSpacing([8], 'gap')};
`;

const NarrowHeadingContainer = styled.div`
  display: grid;
  ${cardStyle};
  overflow: visible;
`;

export const TransactionHeading: React.FC = () => {
  const { data } = useFirstPaintData();
  const accountSummaries = useAccountSummaries();
  const isWide = useMediaLayout(spacing.largeMq);

  if (!accountSummaries || !data) {
    return null;
  }

  return (
    <div data-testid="transaction-heading-container">
      {data.organization.operationalAccount ? (
        <OperationalAccount
          account={data.organization.operationalAccount}
          amountAvailable={data.accountsInfo.accounts
            .filter(account => account.type !== 'Tax')
            .reduce(
              (previousValue, currentValue) =>
                previousValue + (currentValue.balanceNok?.asNumber ?? 0),
              0,
            )}
        />
      ) : null}
      {isWide ? (
        <WideHeading accountSummaries={accountSummaries} />
      ) : (
        <NarrowHeading accountSummaries={accountSummaries} />
      )}
    </div>
  );
};

const NarrowHeading: React.FC<{
  accountSummaries: readonly AccountSummary[];
}> = ({ accountSummaries }) => {
  const [expandedType, setExpandedType] = React.useState<string | null>(null);
  return (
    <NarrowHeadingContainer>
      {accountSummaries.map(accountSummary => {
        const currentIsExpanded = accountSummary.type === expandedType;
        return (
          <NarrowAccountSummary
            key={accountSummary.title}
            accountSummary={accountSummary}
            isExpanded={currentIsExpanded}
            onClick={() =>
              setExpandedType(currentIsExpanded ? null : accountSummary.type)
            }
          />
        );
      })}
    </NarrowHeadingContainer>
  );
};

const WideHeading: React.FC<{
  accountSummaries: readonly AccountSummary[];
}> = ({ accountSummaries }) => (
  <WideHeadingContainer>
    {accountSummaries.map(accountSummary => (
      <WideAccountSummary
        key={accountSummary.type}
        accountSummary={accountSummary}
      />
    ))}
  </WideHeadingContainer>
);

const OperationalAccount: React.FC<{
  account: NonNullable<FirstPaintQuery['organization']['operationalAccount']>;
  amountAvailable: number;
}> = ({ account, amountAvailable }) => (
  <div data-testid="accountsummary-Operational">
    {account.balanceNok?.asNumber === amountAvailable ? null : (
      <div css={spacing.getSpacing([16], 'margin-bottom')}>
        Bedriften har{' '}
        <b>{formatters.formatAmount(amountAvailable, { currency: 'kr' })}</b>{' '}
        tilgjengelig
      </div>
    )}
    <div
      css={css`
        ${cardStyle};
        ${spacing.getSpacing([16], 'padding')};
        ${spacing.getSpacing([8], 'margin-bottom')};

        display: grid;
        align-items: center;
        grid-template-columns: 1fr auto;
      `}
    >
      <div>
        <AmountSpan amount={account.balanceNok?.asNumber ?? 0} />
        <div>Driftskonto</div>
      </div>
      <OrgLink
        css={css`
          :hover {
            text-decoration: underline;
            text-decoration-thickness: 2px;
          }

          -webkit-tap-highlight-color: transparent;
          ${links.defaultLink};
          ${links.blackLink};
          ${spacing.getSpacing([8], 'margin-right')};
        `}
        to={pages.orgSettings.getUrl()}
      >
        Konto
      </OrgLink>
    </div>
  </div>
);
