import styled from '@emotion/styled';
import { Divider as BaseDivider, spacing } from 'folio-common-components';
import { cardStyle } from '../card-style';
export { ProfileHeading } from './heading';
export { UserRoleSelect } from './user-role-select';
export { agentTypeLabels, agentTypeDescriptions } from './user-types';
export { RecreatePromotionSigning } from './sign-promotion';

export const Container = styled.div`
  ${cardStyle};
  ${spacing.getSpacing([16, 48], 'padding')};
`;

export const Divider = styled(BaseDivider)`
  ${spacing.getSpacing([32, 32, 48], 'margin-vertical')};
`;
