import * as React from 'react';
export const LockupHorisontallyLogo = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={99}
    height={34}
    fill="none"
    viewBox="0 0 99 34"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      d="M42 27h5.25v-6.6999h4.22v-4.48h-4.22v-3.19h5.97v-4.63H42V27ZM69.75 7.24H75V27h-5.25V7.24ZM79.875 10.76c.7147-.0217 1.3917-.3204 1.8833-.831.4916-.5106.758-1.1917.7409-1.8946.0171-.703-.2493-1.384-.7409-1.8947-.4916-.5106-1.1686-.8093-1.8833-.831-.7147.0217-1.3917.3204-1.8833.831-.4916.5106-.758 1.1917-.7409 1.8947-.0171.703.2493 1.384.7409 1.8946.4916.5106 1.1686.8093 1.8833.831ZM77.25 12.0601h5.25V27h-5.25V12.0601ZM60.78 11.8801c-3.51 0-7.28 2.4229-7.28 7.7512 0 4.6447 2.93 7.7612 7.28 7.7612s7.28-3.1165 7.28-7.7612c0-5.3283-3.73-7.7512-7.28-7.7512Zm0 4.2828c1.59 0 2.5 1.2667 2.5 3.4684 0 1.3069-.32 3.4785-2.5 3.4785s-2.45-2.1716-2.45-3.4785c0-2.2017.86-3.4684 2.45-3.4684ZM91.5188 11.8801c-3.5 0-7.28 2.4229-7.28 7.7512 0 4.6447 2.93 7.7612 7.28 7.7612s7.28-3.1165 7.28-7.7612c0-5.3283-3.77-7.7512-7.28-7.7512Zm0 4.2828c1.59 0 2.5 1.2667 2.5 3.4684 0 1.3069-.32 3.4785-2.5 3.4785s-2.5-2.1716-2.5-3.4785c0-2.2017.91-3.4684 2.5-3.4684Z"
    />
    <path fill="#fff" d="M19 9v24H1V5a4 4 0 0 1 4-4" />
    <path
      stroke="#000"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M19 9v24H1V5a4 4 0 0 1 4-4"
    />
    <path
      stroke="#000"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M1 19a3.9998 3.9998 0 0 1 4-4"
    />
    <path
      fill="#BDB2F5"
      stroke="#000"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M27 9H9V5a4 4 0 0 0-4-4h18a3.9998 3.9998 0 0 1 4 4v4Z"
    />
    <path
      fill="#FDC7BD"
      stroke="#000"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M27 23H9v-4a3.9998 3.9998 0 0 0-4-4h18a3.9996 3.9996 0 0 1 2.8284 1.1716A3.9996 3.9996 0 0 1 27 19v4Z"
    />
  </svg>
);
